import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'styles/media-queries'
import { Link } from 'gatsby'

const invertColor = color => ({ inverted }) => !inverted ? color : color === '--blue' ? '--white' : '--blue'

export const Element = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';
  font-weight: 400;

  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(12)};
  text-transform: uppercase;
  padding: ${calculateResponsiveSize(6)} ${calculateResponsiveSize(26)};
  cursor: pointer;
  text-decoration: none;

  border: 1px solid var(${invertColor('--white')});

  transition: background-color 0.5s, color 0.5s;

  background-color: var(${invertColor('--blue')});
  color: var(${invertColor('--white')});

  &:visited {
    color: currentColor;
  }
  &:hover {
    background-color: var(${invertColor('--white')});
    color: var(${invertColor('--blue')});
  }
`
