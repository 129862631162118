const breakpoints = {
  phone: 375,
  phoneLarge: 414,
  phoneXL: 576,
  tablet: 768,
  tabletLandscape: 1024,
  desktopSm: 1150,
  desktopSmall: 1250,
  desktopM: 1350,
  desktop: 1440,
  desktopLarge: 1920,
  desktopXLarge: 2048,

  mobile: 768
}

export const mediaMax = (n) => {
  const bpArray = Object.keys(breakpoints).map((key) => [key, breakpoints[key]])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size - 1}px)`]
    return acc
  }, [])

  return result
}

export const mediaMin = (n) => {
  const bpArray = Object.keys(breakpoints).map((key) => [key, breakpoints[key]])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export const calculateResponsiveSize = (px) => `${px / 19.2}vw`

export const calculateMobileResponsiveSize = (px) => `${px / 3.93}vw`
