import React from 'react'

import { Element } from './index.styled'

const Button = ({ title, ...props }) => {
  return (
    <Element
      {...props}
    >
      {title}
    </Element>
  )
}

export default Button
